.header {
  background: linear-gradient(to right, #fff6ef, #e0f6f0, #ffe7e9, #ebffef);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  color: #979797 !important;
  z-index: 99;
  transition: all 0.3s ease;
}
.active {
  border-bottom: 3px solid #be7880;
  padding-bottom: 20px;
  text-decoration: none;
  font-weight: 700 !important;
  text-underline-offset: 7px !important;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  margin: 0 2em;
}

.header_left {
  display: flex;
  align-items: center;
}

.nav_name {
  font-size: 18px;
  color: #4c4c4c;
  /* background-color: #4285f4; */
}

.header_left img {
  margin-right: 1em;
  width: 60px;
  object-fit: cover;
}

.header_left a {
  text-decoration: none;
  color: #4285f4;
  margin: 0 0.8em;
  font-weight: 600;
  font-size: 15px;
  line-height: 27px;
  color: #525252;
}

.header_right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sign_in {
  display: flex;
  align-items: center;
}

.sign_in p {
  color: #979797;
}

.portalprofile img {
  width: 40px;
  height: 50px;
  object-fit: cover;
  filter: drop-shadow(0px 11px 14px rgba(0, 0, 0, 0.25));
}

.avatar_profile {
  margin-left: 0.5em;
}
.navTitles {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navCenterBody {
  /* position: relative; */
  width: fit-content;
}
.dropdownContainer {
  position: relative;

  /* display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  left: 50%; */
}
.dropdownContainer:hover .dropdownLists {
  display: flex;
  width: 200px;
  flex-direction: column;
  padding: 25px 30px;
  gap: 19px;
}
.dropdownLists {
  position: absolute;
  top: 85%;
  left: 30%;
  background: #fafafa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.11);
  z-index: 111;
  display: none;
  margin-top: 5px;
}
.myNav {
  text-decoration: none;
  /* color: black; */
  margin: 0 1em;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* color: #525252; */
  cursor: pointer;
}
.dropdownLists li {
  list-style-type: none;

  cursor: pointer;
}
.dropdownLists li a {
  font-size: 15px;
}
