@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

:root {
  /* --border_color: rgb(151, 151, 151); */
  --border_color: #676767;
  --table_border: #ccc;
  appearance: none;
}

.vendorSupplierContainer input,
.vendorSupplierContainer select {
  border: 1px solid rgb(209 213 219);
  height: 35px;
}

.vendorSupplierContainer textarea {
  border: 1px solid rgb(209 213 219);
}

.vendorSupplierContainer input:focus,
.vendorSupplierContainer select:focus,
.vendorSupplierContainer textarea {
  outline: 2px solid rgb(79 70 229);
}

input,
select {
  font-size: 85%;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.vendorSupplierContainer {
  /* margin: 10em; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainContentContainer {
  width: 90%;
  padding: 2em 0;
}

.b {
  font-weight: bold;
}

.center-wrapper {
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; */
}
.test {
  /* border: 1px solid red; */
}
.pswp__preloader__icn {
  opacity: 0.75;
  width: 14px;
  height: 14px;
  -webkit-animation: clockwise 500ms linear infinite;
  animation: clockwise 500ms linear infinite;
}

.pswp__preloader__cut {
  position: relative;
  width: 7px;
  height: 14px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.pswp__preloader__donut--fake {
  box-sizing: border-box;

  width: 14px;
  height: 14px;

  border: 2px solid red;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;

  background: none;
  margin: 0;
}

.pswp__preloader__donut {
  box-sizing: border-box;

  width: 14px;
  height: 14px;

  border: 2px solid #313131;
  border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;

  position: absolute;
  top: 0;
  left: 0;

  position: absolute;
  top: 0;
  left: 0;

  background: none;
  margin: 0;

  -webkit-animation: donut-rotate 1000ms cubic-bezier(0.4, 0, 0.22, 1) infinite;
  animation: donut-rotate 1000ms cubic-bezier(0.4, 0, 0.22, 1) infinite;
}

@-webkit-keyframes clockwise {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes donut-rotate {
  0% {
    -webkit-transform: rotate(0);
  }

  50% {
    -webkit-transform: rotate(-140deg);
  }

  100% {
    -webkit-transform: rotate(0);
  }
}

@keyframes donut-rotate {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-140deg);
  }

  100% {
    transform: rotate(0);
  }
}
.EachPageSubHeader {
  padding: 1em;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 1em;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  position: sticky;
  top: 7.5%;
  z-index: 10;
}
.EachPageSubHeader2 {
  width: 100%;

  padding: 1em;
  background-color: #fff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 1em;
  border-radius: 5px;
}
.paymentTable thead {
  position: sticky;
  top: 140px;
  background-color: #fff;
}
.paymentTable thead tr th {
  padding: 8px 3px;
  /* padding: 0 3px; */
}
.paymentTable tbody tr td {
  /* padding: 8px 2px; */
}

/* .test {
  border: 1px solid red;
} */
